$(document).foundation();

$(document).ready(function() {
	//$("#t2g-carousel").owlCarousel();
//	$("#t2g-carousel").owlCarousel({

//		navigation : false, // Show next and prev buttons
//		slideSpeed : 300,
//		paginationSpeed : 400,
//		singleItem:true,
//		autoPlay: 7000

		// "singleItem:true" is a shortcut for:
		// items : 1,
		// itemsDesktop : false,
		// itemsDesktopSmall : false,
		// itemsTablet: false,
		// itemsMobile : false

//	});

	var time = 7; // time in seconds

	var $progressBar,
		$bar,
		$elem,
		isPause,
		tick,
		percentTime,
		number_of_slides,
		slide_number;

		slide_number = 0;
		number_of_slides = 4;

	//Init the carousel
	$("#t2g-carousel").owlCarousel({
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		afterInit : progressBar,
		afterMove : moved,
		startDragging : pauseOnDragging
	});

	//Init progressBar where elem is $("#t2g-carousel")
	function progressBar(elem){
		$elem = elem;
		//build progress bar elements
		buildProgressBar();
		//start counting
		start();
	}

	//create div#progressBar and div#bar then append to $("#t2g-carousel")
	function buildProgressBar(){
		$progressBar = $("<div>",{
			id:"progressBar"
		});
		$bar = $("<div>",{
			id:"bar"
		});
		$progressBar.append($bar).appendTo($elem);
	}

	function start() {
		//reset timer
		percentTime = 0;
		isPause = false;
		//run interval every 0.01 second
		tick = setInterval(interval, 10);

		var class_to_remove = 'slide-' + slide_number;
		$('#progressBar #bar').removeClass(class_to_remove);
		if (slide_number % number_of_slides === 0) {
			slide_number = 0;
		}
		slide_number++;
		var class_to_add = 'slide-' + slide_number;
		$('#progressBar #bar').addClass(class_to_add);
	};

	function interval() {
		if(isPause === false){
			percentTime += 1 / time;
			$bar.css({
				width: percentTime+"%"
			});
			//if percentTime is equal or greater than 100
			if(percentTime >= 100){
				//slide to next item
				$elem.trigger('owl.next')
			}
		}
	}

	//pause while dragging
	function pauseOnDragging(){
		isPause = true;
	}

	//moved callback
	function moved(){
		//clear interval
		clearTimeout(tick);
		//start again
		start();
	}

	//uncomment this to make pause on mouseover
	// $elem.on('mouseover',function(){
	//   isPause = true;
	// })
	// $elem.on('mouseout',function(){
	//   isPause = false;
	// })

	//Init the carousel
	$("#participating-groups").owlCarousel({
		autoPlay: 7000,
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		transitionStyle : "fade",
		navigation: false,
		pagination: false
	});

	$("#participating-groups-small").owlCarousel({
		autoPlay: 7000,
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		transitionStyle : "fade",
		navigation: false,
		pagination: false
	});

	$( ".open" ).click(function(event) {
		event.preventDefault();
		$(this).children(".icon").toggleClass('icon-plus icon-minus');
		$(this).next('.slidedown').slideToggle();
	});

	$( ".small-search a" ).click(function(event) {
		event.preventDefault();
		$(".small-search-input").fadeToggle();
	});

	$( ".small-search-input .icon-cancel" ).click(function(event) {
		event.preventDefault();
		$(".small-search-input").fadeToggle();
	});

	$('img').on('load', function () {
		$(document).foundation('equalizer', 'reflow');
	});

	if ($('body.page').length || $('body.subpage').length) {
		var site_section = $('body').data('section');
		var menu_text = '';
		$('.dropdown.menu.show-for-large > li > a').each(function(index) {
			menu_text = $(this).html();
			if (site_section.toLowerCase() === menu_text.toLowerCase()) {
				$(this).parent().addClass('active-section');
			}
		});
	}
});

$(window).resize(function () {
	// Use modernizer or some other to to test for flexbox
	if (!$("html").hasClass("flexbox")) {
		if ($(window).outerWidth() > 640) {
			var w = Math.floor(100 / $(".top-bar > ul > li").length) + "%"
			console.log(w);
			$(".top-bar > ul > li").css({
				"width": w,
				"float": "left"
			});
		} else {
			$(".top-bar > ul > li").removeAttr('style');
		}
	}
});
$(window).resize();
